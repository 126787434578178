import React, { useEffect } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { Link } from 'gatsby';

import Layout from '../components/layout';
import SEO from '../components/seo';
import HeaderSection from '../components/HeaderSection/HeaderSection';
import Section from '../components/Section/Section';
import Headline from '../components/Headline/Headline';
import HeadlineWhite from '../components/HeadlineWhite/HeadlineWhite';
import FlipperCard from '../components/FlipperCard/FlipperCard';

import AwesomeSlider from 'react-awesome-slider';
import 'react-awesome-slider/dist/styles.css';
import image1 from '../images/md_drKostka_1.jpg';
import image2 from '../images/md_drKostka_2_var2.jpg';
import image3 from '../images/md_drKostka_3.jpg';
import xsimage1 from '../images/xs_drKostka_1.jpg';
import xsimage2 from '../images/xs_drKostka_2.jpg';
import xsimage3 from '../images/xs_drKostka_3.jpg';

import gifAnim from '../animations/Unterschrift_gut_zeichnen_3.gif';

import Aos from 'aos';
import 'aos/dist/aos.css';

import lottie from 'lottie-web';
import signAnimation from '../animations/signature_lotti_animation.json';
import testAnimation from '../animations/data_unterschrift_test_esp.json';

const mobileUser = false;

const IndexPage = () => {
  let animationContainer = React.createRef();

  useEffect(() => {
      Aos.init({duration: 3000 });
      const anim = lottie.loadAnimation({
        container: animationContainer.current,
        animationData: testAnimation,
        loop: true
      })
  }, []);
  return(
  <Layout headerWhite home>
    <SEO
      title="Dr. Kostka | integrative Medizin und Ästhetik"
      description="Dr. K. Kostka bietet integrative Medizin und Ästhetik mitten in Bochum. Moderne Praxis ✓ Top Ausstattung ✓ Vereinbaren Sie jetzt einen Termin ✓"
    />
    <div className="bubblesOnSlider display-md">
      <AwesomeSlider
        className="fullWidth-slider"
        organicArrows={false}
        >
        <div data-src={image1} />
        <div data-src={image2} />
        <div data-src={image3} />
      </AwesomeSlider>
    </div>
    <div className="bubblesOnSlider display-xs">
      <AwesomeSlider
        className="fullWidth-slider"
        organicArrows={false}
        >
        <div data-src={xsimage1} />
        <div data-src={xsimage2} />
        <div data-src={xsimage3} />
      </AwesomeSlider>
    </div>
    
    <div className="bubbleSection">
    <Container>
      <Section>
        <Headline h1 subtitle="Praxis für integrative Medizin und Ästhetik" >Praxis Dr. Kathrin Kostka</Headline>
        <Row>
          <Col xs={12} lg={12}>
            <p>
              <span className="bigLetter">D</span>
              ie integrative Medizin verbindet moderne Schulmedizin mit wissenschaftlich belegten, alternativen, sanften Therapieformen.
              <br/>
              <br/>
              In diesem ganzheitlichen Konzept stehen Sie im Mittelpunkt.
              Ich werde Ihnen helfen, gesund zu werden und vital zu bleiben.
              <br/>
              <br/>
              Innovative biologische Verfahren mit Eigenblut/PRP, die Mesotherapie mit ihrer Kombination aus Akupunktur, Neuraltherapie und Arzneimischungen sowie Ernährungs- und Schmerztherapie sind die Eckpfeiler meines Gesundheitskonzeptes.
            <br/>
              <br/>
              Jeder Mensch ist einzigartig und schön. Positive Ausstrahlung und erholtes Aussehen geben ein gutes Lebensgefühl. Durch gezielte ästhetische Behandlungen lassen sich natürliche Schönheit hervorheben und Hautalterungen entgegenwirken. Wir werden gemeinsam die für Sie persönlichen  Therapieoptionen ausführlichen besprechen.
              <br/>
              <br/>
              Ich freue mich auf Ihren Besuch in meiner Praxis in der Bochumer Innenstadt.
            </p>
          </Col>
          {/*<Col xs={12} lg={6}>
            <p style={{ paddingTop: 15 }}>
              Jeder Mensch ist einzigartig und schön. Positive Ausstrahlung und erholtes Aussehen geben ein gutes Lebensgefühl. Durch gezielte ästhetische Behandlungen lassen sich natürliche Schönheit hervorheben und Hautalterungen entgegenwirken. Wir werden gemeinsam die für Sie persönlichen  Therapieoptionen ausführlichen besprechen.
              <br/>
              Ich freue mich auf Ihren Besuch.
            </p>
          </Col>*/}
        </Row>
        <Row className="justify-content-center">
          <Col xs={8} md={4} lg={4}>
          {/*<div className="unterschrift" ref={animationContainer}>

        </div>*/}
            <img src={gifAnim} alt="Dr. K. Kostka"></img>
          </Col>
        </Row>
      </Section>
    </Container>
    </div>
    <Section red>
      <Row noGutters>
        <Col xs={12} lg={6}>
          <div className="fullWidth-col-image" />
        </Col>

        <Col xs={12} lg={6}>
          <div className="fullWidth-col-content right" data-aos={mobileUser ? "fade-up" : "fade-left"}>
            <HeadlineWhite light>Dr. Kathrin Kostka</HeadlineWhite>
            <ul>
              <li>Studium der Pharmazie und  Humanmedizin in Pavia, Italien</li>
              <li>Abschluss in Humanmedizin 1991</li>
              <li>Facharztausbildung zur Anästhesiologin und Notfallmedizinerin in Deutschland</li>
              <li>Fachärztin für Anästhesiologie 1999</li>
              <li>Facharzttätigkeit seit über 20 Jahren</li>
              <li>Langjährige Weiterbildung in Komplementärmedizin:
                <ul>
                  <li>Naturheilverfahren</li>
                  <li>Homöopathie</li>
                  <li>Traditionelle und  integrative Medizin</li>
                  <li>Ästhetische Medizin</li>
                </ul>
              </li>
            </ul>
          </div>
        </Col>
      </Row>
    </Section>
    <Section noPaddingBottom>
      <Container className={"noBottomPadding"}>
        <Row data-aos="fade-up">
          <Col xs={12} md={6}>
            <div >
          <Headline>
          Zertifizierungen
          </Headline>
            
              <ul>
                <li>Ästhetische Medizin</li>
                <li>Ernährungsmedizin</li>
                <li>Schmerztherapie</li>
                <li>Mesotherapie</li>
                <li>Diplom der Österreichischen Gesellschaft für Akupunktur und Auriculotherapie</li>
                <li>Zertifikat Schädelakupunktur nach Yamamoto</li>
              </ul>
            
            </div>
          </Col>
          <Col xs={12} md={6}>
            <div className={"mobilePaddingTop"}>
          <Headline>
            Mitgliedschaften
          </Headline>
          
              <ul>
                <li><b>DGBT: </b>Deutsche Gesellschaft für ästhetische Botulinum- und Fillertherapie e.V.</li>
                <li><b>DGÄ: </b>Deutsche Gesellschaft für Ästhetik e.V.</li>
                <li><b>DGE: </b>Deutsche Gesellschaft für Ernährung e.V.</li>
                <li><b>FACM: </b>Forschungsgruppe Akupunktur</li>
                <li><b>IGOST: </b>Interdisziplinäre Gesellschaft für orthopädische/ unfallchirurgische und allgemeine Schmerztherapie e.V.</li>
                <li><b>DGM: </b>Deutsche Gesellschaft für Mesotherapie e.V.</li>
              </ul>
            
            </div>
          </Col>
        </Row>
      </Container>
    </Section>
    {/*<Section dark>
        <Container>
          <Row style={{ margin: 0 }}>
            <Col>
            Wenn Sie mehr über unser Leistungsspektrum erfahren wollen, schauen Sie gerne in den Bereichen <Link to="/leistungen/aesthetik">Ästhetik</Link>, <Link to="/leistungen/schmerz">Schmerz</Link> oder <Link to="/leistungen/schmerz">Ernährung</Link> vorbei. Dort haben verständliche Erklärungen zu verschiedenen Verfahren zusammengestellt. Zögern Sie nicht, uns bei Fragen anzusprechen.
            </Col>
          </Row>
        </Container>
    </Section>*/}
  </Layout>
  )
};

export default IndexPage;

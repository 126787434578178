import React from 'react';
import PropTypes from 'prop-types';
import { Container } from 'reactstrap';

import styles from './HeaderSection.module.scss';

const HeaderSection = ({ home }) => (
  <div className={[styles.wrapper, home ? styles.home : null].join(' ')}>
    <Container />
  </div>
);

HeaderSection.propTypes = {
  home: PropTypes.bool,
};

HeaderSection.defaultProps = {
  home: false,
};

export default HeaderSection;
